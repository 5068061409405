"use client";

import { memo, PropsWithChildren, useId, useMemo } from "react";
import { LayoutProps, motion } from "framer-motion";
import ReactMarkdown from "react-markdown";
import { marked } from "marked";
import remarkGfm from "remark-gfm";
import rehypeHighlight from "rehype-highlight";
import Link from "next/link";

import { TypographyP } from "@/components/ui/typography";
import LoadingSpinner from "@/components/loading-spinner";
import { cn } from "@/lib/utils";

import styles from "./markdown.module.css";

interface AnimatedMessageProps extends PropsWithChildren, LayoutProps {
  key: string;
  className?: string;
}
export function AnimatedMessage({ className, ...props }: AnimatedMessageProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      className={cn("h-fit w-full max-w-9/10 empty:hidden", className)}
      {...props}
    />
  );
}

const MemoizedMarkdownBlock = memo(
  ({ content }: { content: string }) => {
    return (
      <div className={styles.markdown}>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeHighlight]}
          components={{
            a: ({ node, ...props }) => (
              <Link
                {...props}
                href={props.href!}
                target="_blank"
                rel="noreferrer"
              />
            ),
            pre: ({ className, ...props }) => (
              <pre
                className={cn(
                  className,
                  "bg-muted overflow-auto rounded-lg border",
                )}
                {...props}
              />
            ),
          }}
        >
          {content}
        </ReactMarkdown>
      </div>
    );
  },
  (prevProps, nextProps) => {
    if (prevProps.content !== nextProps.content) return false;
    return true;
  },
);

MemoizedMarkdownBlock.displayName = "MemoizedMarkdownBlock";

function parseMarkdownIntoBlocks(markdown: string): string[] {
  const tokens = marked.lexer(markdown);
  return tokens.map((token) => token.raw);
}

const MemoizedMarkdown = memo(
  ({ content, id }: { content: string; id: string }) => {
    const blocks = useMemo(() => parseMarkdownIntoBlocks(content), [content]);

    return blocks.map((block, index) => (
      <MemoizedMarkdownBlock content={block} key={`${id}-block_${index}`} />
    ));
  },
);

MemoizedMarkdown.displayName = "MemoizedMarkdown";

export function BotTextMessage({ id, text }: { id: string; text: string }) {
  return (
    <AnimatedMessage
      key={"bot" + id}
      className="text-card-foreground h-fit w-fit max-w-full"
    >
      <MemoizedMarkdown id={id} content={text} />
    </AnimatedMessage>
  );
}

export function UserMessage({ children }: PropsWithChildren) {
  const id = useId();
  return (
    <AnimatedMessage
      key={"user" + id}
      layout
      className="bg-primary text-background whitespace-wrap w-fit self-end rounded-3xl px-3 py-2"
    >
      <TypographyP className="m-0">{children}</TypographyP>
    </AnimatedMessage>
  );
}

export function SpinnerMessage({ message }: { message?: string }) {
  const id = useId();

  return (
    <AnimatedMessage
      key={"loading" + id}
      className="text-card-foreground"
      layout
    >
      <TypographyP className="inline-flex items-center gap-3 overflow-hidden leading-5">
        <LoadingSpinner size={16} />
        {message || "Thinking..."}
      </TypographyP>
    </AnimatedMessage>
  );
}
