import { KeyboardEvent } from "react";

import { Button } from "../ui/button";
import { ChatProps } from "@/app/(chat)/types";
import { Textarea } from "../ui/textarea";
import { SendHorizonal } from "lucide-react";

const ChatInput = ({
  input,
  onInputChange: handleInputChange,
  onSubmit: handleSubmit,
}: ChatProps) => {
  // const { input, handleInputChange, handleSubmit } = useChat();

  /**
   * A handler that submits the form when the enter key is pressed
   * by "clicking" the button to trigger the form submission
   *
   * It will add a line-break to the input when the enter key is pressed
   * while the user is also pressing the shift key
   *
   * @param event - The keypress event
   */
  function handleKeyPress(event: KeyboardEvent<HTMLTextAreaElement>) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-card align-center flex h-fit flex-row gap-2 rounded-2xl p-2 transition-all"
    >
      <Textarea
        className="min-h-12 w-full rounded-lg px-4 py-[.6rem] leading-relaxed transition-all"
        rows={1}
        autoFocus
        placeholder="What would you like to know?"
        value={input}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
      />
      <Button
        id="send-button"
        title="Submit your message"
        type="submit"
        disabled={!input}
        className="h-12 w-12 rounded-lg shadow-xs"
      >
        <SendHorizonal />
      </Button>
    </form>
  );
};

export default ChatInput;
