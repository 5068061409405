"use client";

import Link from "next/link";
import moment from "moment";
import { ArrowUpRight } from "lucide-react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { TypographyH3, TypographyP } from "@/components/ui/typography";
import { cn } from "@/lib/utils";
import { SelectArticle } from "@/db/schema";

interface Props {
  article: SelectArticle;
  inChat?: boolean;
}

const ArticleCard = ({ article, inChat }: Props) => {
  const base = inChat ? "chat-article" : "article";
  return (
    <Link href={`/${base}/${article.slug}`} scroll={false} className="w-full">
      <Card
        className={cn(
          "hover:bg-accent bg-card hover:text-accent-foreground flex w-full flex-col rounded-3xl transition-all",
          !inChat && "min-h-64",
        )}
      >
        <CardHeader className="flex flex-row justify-end">
          <ArrowUpRight />
        </CardHeader>
        <CardContent className="flex grow flex-col items-end justify-start"></CardContent>
        <CardFooter>
          <div className="space-y-2">
            <TypographyH3>{article.title}</TypographyH3>
            <div>
              <TypographyP>
                Created {moment(article.createdAt).fromNow()}
              </TypographyP>
            </div>
          </div>
        </CardFooter>
      </Card>
    </Link>
  );
};

export default ArticleCard;
