"use client";

import {
  AnimatedMessage,
  BotTextMessage,
  SpinnerMessage,
  UserMessage,
} from "@/app/(chat)/messages";
import { useChat } from "@ai-sdk/react";
import { SelectArticle } from "@/db/schema";
import ArticleCard from "@/components/article-card";

import { AnimatePresence } from "framer-motion";
import { AutoScroll } from "@/components/auto-scroll";
import { InitialSuggestions } from "@/components/initial-suggestions";
import { ChatInput } from "@/components/chat-input";
// import RollingText from "@/components/rolling-text";

interface ProjectRecResult {
  message: string;
  projects: SelectArticle[];
}

const ChatPage = () => {
  const { messages, input, handleInputChange, handleSubmit, append } = useChat({
    maxSteps: 5,

    // run client-side tools that are automatically executed:
    async onToolCall({ toolCall }) {
      if (toolCall.toolName === "tool-name") {
        return "whatever";
      }
    },
  });

  return (
    <div id="content-container" className="mx-auto w-full max-w-2xl px-4 pb-32">
      <div id="chat-area" className="flex flex-col gap-8">
        <AnimatePresence>
          {messages.map((message, messageIndex) =>
            message.role === "user" ? (
              <UserMessage key={`user-${message.id}-${messageIndex}`}>
                {message.content}
              </UserMessage>
            ) : (
              <div
                className="flex flex-col gap-4"
                key={`bot-${message.id}-${messageIndex}`}
              >
                {message.parts.map((part, partIndex) => {
                  if (part.type === "text") {
                    const id = `text-${message.id}-${partIndex}`;
                    return <BotTextMessage key={id} id={id} text={part.text} />;
                  } else if (part.type === "tool-invocation") {
                    const { toolInvocation } = part;

                    switch (toolInvocation.toolName) {
                      case "recommendProjects":
                        if (toolInvocation.state === "call") {
                          return (
                            <SpinnerMessage
                              key={`spinner-${toolInvocation.toolCallId}`}
                              message="I'm looking for some projects for you..."
                            />
                          );
                        } else if (toolInvocation.state === "result") {
                          const data: ProjectRecResult = toolInvocation.result;
                          return data.projects.map((project) => (
                            <AnimatedMessage
                              layout
                              key={`project-${project.id}-${toolInvocation.toolCallId}`}
                              className="max-w-full"
                            >
                              <ArticleCard inChat article={project} />
                            </AnimatedMessage>
                          ));
                        }
                        break;
                      case "accessKnowledge":
                        if (toolInvocation.state === "call") {
                          return (
                            <SpinnerMessage
                              key={`spinner-${toolInvocation.toolCallId}`}
                              message="I'm trying to remember..."
                            />
                          );
                        }
                        break;
                      default:
                        return null;
                    }
                  }
                })}
              </div>
            ),
          )}
          {messages.length % 2 ? <SpinnerMessage key="final-spinner" /> : null}
        </AnimatePresence>
      </div>

      <AutoScroll />

      <div
        id="input-area"
        className="fixed bottom-0 left-1/2 z-10 flex h-fit w-full max-w-2xl -translate-x-1/2 flex-col gap-2 overflow-visible p-2 md:pb-8"
      >
        <InitialSuggestions onSend={append} messages={messages} />

        <ChatInput
          input={input}
          onInputChange={handleInputChange}
          onSubmit={handleSubmit}
        />
      </div>

      {/* <RollingText /> */}
    </div>
  );
};

export default ChatPage;
