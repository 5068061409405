"use client";

import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

import useAtBottom from "@/lib/hooks/useAtBottom";

export function AutoScroll() {
  const isAtBottom = useAtBottom();
  const { ref, entry, inView } = useInView({
    rootMargin: "0px 0px -5px 0px",
    trackVisibility: true,
    delay: 100,
  });

  useEffect(() => {
    if (isAtBottom && !inView) {
      entry?.target.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [inView, isAtBottom, entry]);

  return <div ref={ref} className="h-px w-full" />;
}
