import { cn } from "@/lib/utils";
import { useState, ChangeEvent } from "react";

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export interface TextareaRef
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  blur: () => void;
}

/**
 * A auto resizing textarea component
 */
const Textarea = ({ className, ...props }: TextareaProps) => {
  return (
    <textarea
      className={cn(
        "border-input placeholder:text-muted-foreground focus-visible:ring-ring no-scrollbar flex max-h-[300px] min-h-[60px] w-full resize-none rounded-md border-2 bg-transparent px-3 py-2 ring-0 transition-all duration-300 focus-visible:ring-1 focus-visible:outline-hidden disabled:cursor-not-allowed disabled:opacity-50",
        className,
      )}
      {...props}
    />
  );
};

Textarea.displayName = "Textarea";

export { Textarea };
