import { ReactElement, useId } from "react";
import { motion } from "framer-motion";
import { ArrowRight } from "lucide-react";
import { Message } from "ai";

import { ChatProps } from "@/app/(chat)/types";
import { TypographyH3, TypographyMuted } from "../ui/typography";
import { Card, CardContent, CardHeader } from "../ui/card";

export interface InitialSuggestionProps extends Pick<ChatProps, "onSend"> {
  title: string;
  description: string;
  icon?: ReactElement;
}

const variants = {
  open: {
    y: 0,
    opacity: 1,
  },
  closed: {
    y: 50,
    opacity: 0,
  },
};

const MotionCard = motion.create(Card);

const InitialSuggestion = (props: InitialSuggestionProps) => {
  const { title, description, icon, onSend } = props;
  const id = useId();

  const handleSuggestionClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const message: Message = {
      content: description,
      role: "user",
      id,
    };

    onSend?.(message);
  };

  return (
    <>
      <MotionCard
        variants={variants}
        animate="open"
        exit="closed"
        key={title}
        onClick={handleSuggestionClick}
        className="bg-card hover:bg-accent hover:text-accent-foreground h-fit w-full min-w-48 cursor-pointer gap-0 rounded-2xl py-4 transition-colors"
      >
        <CardHeader className="flex flex-row justify-end p-0 px-4">
          <ArrowRight />
        </CardHeader>
        <CardContent className="space-y-2 px-4">
          <span className="inline-flex items-end gap-4">
            {icon}
            <TypographyH3 className="m-0">{title}</TypographyH3>
          </span>
          <TypographyMuted className="mb-0">{description}</TypographyMuted>
        </CardContent>
      </MotionCard>
    </>
  );
};

export default InitialSuggestion;
