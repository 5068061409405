"use client";

import { UIMessage } from "ai";
import { AnimatePresence, motion } from "framer-motion";
import { Clock, NotebookTabs, SmileIcon } from "lucide-react";

import { ChatProps } from "@/app/(chat)/types";
import InitialSuggestion from "./single-suggestion";

interface Props extends Pick<ChatProps, "onSend"> {
  messages: UIMessage[];
}

const InitialSuggestions = ({ messages, ...rest }: Props) => {
  return (
    <AnimatePresence mode="wait">
      {!messages.length && (
        <motion.div
          animate={{ opacity: 1, transition: { staggerChildren: 0.2 } }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="flex w-full flex-col gap-2"
        >
          <InitialSuggestion
            key="projects"
            icon={<NotebookTabs width={24} height={24} />}
            title="Projects"
            description="Tell me about some of your projects"
            {...rest}
          />
          <InitialSuggestion
            key="recent"
            icon={<Clock width={24} height={24} />}
            title="Most recent"
            description="What's something you've done recently?"
            {...rest}
          />
          <InitialSuggestion
            key="mood"
            icon={<SmileIcon width={24} height={24} />}
            title="Mood"
            description="How are you feeling today?"
            {...rest}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default InitialSuggestions;
