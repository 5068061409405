import { useCallback, useEffect, useState } from "react";

function useAtBottom(offset = 0) {
  const [isAtBottom, setIsAtBottom] = useState(false);

  /**
   * Gets called on every scroll event of the window
   */
  const handleScroll = useCallback(() => {
    const isScrollAtBottom =
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - offset;

    setIsAtBottom(isScrollAtBottom);
  }, [offset]);

  // Listen to window scroll events
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll, offset]);

  return isAtBottom;
}

export default useAtBottom;
